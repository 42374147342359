import { makeAutoObservable } from 'mobx';

class PopupStoreModel {
	isOpen = false;
	constructor() {
		makeAutoObservable(this);
	}
	togglePopup = () => {
		this.isOpen = !this.isOpen;
	};
	closePopup = () => {
		this.isOpen = false;
	};
	openPopup = () => {
		this.isOpen = true;
	};
}

class QrStoreModel {
	qrIsOpen = false;
	qrUrl = null;
	qrValidity = null;
	constructor() {
		makeAutoObservable(this);
	}
	toggleQr = () => {
		this.qrIsOpen = !this.qrIsOpen;
	};
	closeQr = () => {
		this.qrIsOpen = false;
	};
	openQr = () => {
		this.qrIsOpen = true;
	};
	setQrUrl = (value) => {
		this.qrUrl = value;
	};
	setQrValidity = (value) => {
		this.qrValidity = value;
	};
	reset = () => {
		this.qrIsOpen = false;
		this.qrUrl = null;
		this.qrValidity = null;
	};
}

class UserStoreModel {
	user = null;
	userStatusCode = null;

	constructor() {
		makeAutoObservable(this);
	}
	setUser = (value) => {
		this.user = value;
	};
	getUser = () => {
		return this.user;
	};

	setUserStatusCode = (value) => {
		this.userStatusCode = value;
	};
	getUserStatusCode = () => {
		return this.userStatusCode;
	};
	reset = () => {
		this.user = null;
		this.userStatusCode = null;
	};
}

class AuthStoreModel {
	authProfile = null;
	constructor() {
		makeAutoObservable(this);
	}
	setAuthProfile = (value) => {
		this.authProfile = value;
	};
	reset = () => {
		this.authProfile = null;
	};
}

class GuidesStoreModel {
	allRightMessageType = ''; // 'logined' | 'onboarded' | 'id-card' | 'homework'
	isGuideStepUpdate = false;
	constructor() {
		makeAutoObservable(this);
	}
	setAllRightMessageType = (value) => {
		this.allRightMessageType = value;
	};
	guideStepUpdate = () => {
		this.isGuideStepUpdate = !this.isGuideStepUpdate;
	};
	reset = () => {
		this.allRightMessageType = '';
		this.isGuideStepUpdate = false;
	};
}

class DocumentsStoreModel {
	documents = [];
	pagesCount = 0;
	currentPage = 1;

	constructor() {
		makeAutoObservable(this);
	}

	setDocuments = (value, pagesCount) => {
		this.documents = value;
		this.pagesCount = pagesCount || 1;
	};

	setCurrentPage = (value) => {
		this.currentPage = value;
	};
	reset = () => {
		this.documents = [];
		this.pagesCount = 0;
		this.currentPage = 1;
	};
}

export const STORES = {
	PopupStore: new PopupStoreModel(),
	QrStore: new QrStoreModel(),
	UserStore: new UserStoreModel(),
	AuthStore: new AuthStoreModel(),
	GuidesStore: new GuidesStoreModel(),
	DocumentsStore: new DocumentsStoreModel(),
};

export const resetStores = () => {
	Object.values(STORES).forEach(store => store?.reset());
};
