import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../constants/paths';
import {
	AllRightMessagePopup,
	setNodeIsDisabled,
	onCleanNodes,
	onPulse,
	onTooltip
} from 'common-frontend/components/guides';

export const Guides = observer(() => {
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	const { AuthStore, QrStore, UserStore, DocumentsStore, GuidesStore } = useStores();
	const { authProfile } = AuthStore;
	const { qrIsOpen } = QrStore;
	const { user, userStatusCode } = UserStore;
	const { documents } = DocumentsStore;
	const { allRightMessageType, setAllRightMessageType, isGuideStepUpdate } = GuidesStore;

	const debounceRef = useRef(0);
	const currStepRef = useRef('');

	const [ allRightPopupIsOpen, setAllRightPopupIsOpen ] = useState(false);

	const routineRunner = useCallback(() => {
		onTooltip('to-school-activities-link', t('tooltips.school-activities-link-tooltip'),'top', 'start', true);
		onTooltip('to-documents-link', t('tooltips.to-documents-page-tooltip'),'top', 'start', true);
	}, [ setAllRightMessageType, t ]);

	const stepRunners = useMemo(() => (
		{
			beforeAuth() {
				onPulse('auth-link');
				setAllRightMessageType('logined');
			},
			beforeAuthPopup() {
				onCleanNodes([ 'auth-link', 'to-university-link' ]);
				setAllRightPopupIsOpen(true);
			},
			userNotOnboarded() {
				onCleanNodes([ 'auth-link' ]);
				onPulse('qr-button');
				onTooltip('qr-button', t('tooltips.click-for-qr'));
			},
			qrPopup() {
				onCleanNodes([ 'qr-button' ]);
				setAllRightMessageType('onboarded');
			},
			userWaitOnboarding() {
				onCleanNodes([ 'auth-link', 'qr-button' ]);
				setAllRightMessageType('onboarded');
			},
			userOnboarded() {
				setAllRightPopupIsOpen(true);
			},
			userPage() {
				onPulse('back-link');
			},
			beforeActivitiesPage() {
				onCleanNodes([ 'auth-link' ]);
				setNodeIsDisabled('to-university-link', false);

				const isCurrentStep = sessionStorage.getItem('max_done_guide_step') === 'beforeActivitiesPage';

				if (isCurrentStep) {
					onPulse('to-school-activities-link');
					onPulse('to-university-link');
				}

				onTooltip('to-school-activities-link', t('tooltips.school-activities-link-tooltip'),'top', 'start', !isCurrentStep);
				onTooltip('to-university-link', t('tooltips.uni-link-tooltip'), 'bottom', 'start', !isCurrentStep);
			},
			beforeSendId() {
				onPulse('send-id-button');
			},
			beforeSendHomework() {
				onCleanNodes([ 'send-id-button' ]);
				onPulse('send-homework-button');
			},
			beforeDocumentsPage() {
				onCleanNodes([ 'send-homework-button', 'send-id-button' ]);

				const isCurrentStep = sessionStorage.getItem('max_done_guide_step') === 'beforeDocumentsPage';

				if (isCurrentStep) {
					onPulse('to-documents-link');
				}

				onTooltip('to-documents-link', t('tooltips.to-documents-page-tooltip'),'top', 'start', !isCurrentStep);
			},
			documentsPage() {
				onPulse('get-documents-button');
			},
			gotDocumentsList() {
				onCleanNodes([ 'get-documents-button' ]);
			},
			documentDownloaded() {
				onCleanNodes([ 'download-documents-button-1' ]);
			}
		}
	), [ setAllRightMessageType, t ]);

	const updateStep = (forceUpdate = false) => {
		const validationRules = {
			beforeAuth: pathname === PATHS.home && !authProfile, 																																		// user open page and not authenticated, pulse login button
			beforeAuthPopup: pathname === PATHS.home && authProfile && allRightMessageType === 'logined', 																								// user clicked to login button; remove pulse login button, open message popup
			userNotOnboarded: pathname === PATHS.home && authProfile && !qrIsOpen && !user && userStatusCode !== '403', 																				// user authenticated, getUser 404; pulse qr button, qr tooltip
			qrPopup: pathname === PATHS.home && authProfile && qrIsOpen && !user, 																														// user open qr, getUser 403; remove qr tooltip and pulse qr button
			userWaitOnboarding: pathname === PATHS.home && authProfile && !qrIsOpen && !user && userStatusCode === '403', 																				// user close qr, getUser 403;
			userOnboarded: pathname === PATHS.home && authProfile && user && allRightMessageType === 'onboarded', 																						// getUser 200; open message popup
			userPage: pathname === PATHS.user && user,																																							// getUser 200; user page
			beforeActivitiesPage: pathname === PATHS.home && authProfile && user, 																														// getUser 200, clicked "all right"; pulse link to university
			beforeSendId: pathname === PATHS.portal && authProfile && user && !sessionStorage.getItem('school_id_was_sent'), 																		// user open school activities page, school id was not sent
			beforeSendHomework: pathname === PATHS.portal && authProfile && user && sessionStorage.getItem('school_id_was_sent') && !sessionStorage.getItem('homework_was_sent'), 				// user open school activities page, school id was sent, homework was not sent
			beforeDocumentsPage: pathname === PATHS.portal && authProfile && user && sessionStorage.getItem('school_id_was_sent') && sessionStorage.getItem('homework_was_sent'), 				// user open school activities page, school id was sent, homework was not sent
			documentsPage: pathname === PATHS.documents && authProfile && user && !documents.length, 																									// user open documents page
			gotDocumentsList: pathname === PATHS.documents && authProfile && user && documents.length && !sessionStorage.getItem('document_downloaded'), 											// user got documents and documents list is not empty
			documentDownloaded: pathname === PATHS.documents && authProfile && user && documents.length && sessionStorage.getItem('document_downloaded'),											// user downloaded document
		};

		const stepValidator = () => {
			for (let key in validationRules) {
				if (validationRules[key]) {
					return key;
				}
			}
		};

		const setNewStep = () => {
			const newStep = stepValidator();

			if (newStep && (currStepRef.current !== newStep || forceUpdate)) {
				const allSteps = Object.keys(stepRunners);
				const newStepIndex = allSteps.indexOf(newStep);
				const maxDoneStepIndex = allSteps.indexOf(sessionStorage.getItem('max_done_guide_step'));

				if (maxDoneStepIndex < newStepIndex) {
					sessionStorage.setItem('max_done_guide_step', newStep.toString());
				}

				currStepRef.current = newStep;
				console.info('Run step', currStepRef.current);

				routineRunner();
				stepRunners[currStepRef.current]();
			}
		};

		return setNewStep();
	};

	useEffect(() => {
		const handleUpdate = () => {
			clearTimeout(debounceRef.current);
			debounceRef.current = setTimeout(updateStep, 300);
		};
		handleUpdate();
	}, [ authProfile, qrIsOpen, user, documents.length, allRightMessageType, pathname, userStatusCode ]);

	useEffect(() => {
		clearTimeout(debounceRef.current);
		updateStep(true); // lang changes or external trigger should be applied immediately
	}, [ i18n.language, isGuideStepUpdate ]);

	const allRightHandler = useCallback(() => {
		setAllRightPopupIsOpen(false);
		setAllRightMessageType('');
	}, [ allRightMessageType ]);

	return (
		<AllRightMessagePopup messageType={allRightMessageType} isOpen={allRightPopupIsOpen} handler={allRightHandler}/>
	);
});